import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'
import Image from 'gatsby-image'

class Project extends React.Component {
  render() {
    const { name, description, icon, url } = this.props

    return (
      <Link to={url} style={{ color: `inherit` }}>
        <div
          style={{ display: `flex`, alignItems: `center`, margin: `30pt 0` }}
        >
          <Image
            fixed={icon}
            alt={name}
            style={{
              flexShrink: 0,
              marginRight: `16pt`,
            }}
          />
          <div>
            <h3 style={{ margin: 0 }}>{name}</h3>
            <div
              style={{
                lineHeight: `18pt`,
                fontSize: rhythm(1 / 2),
              }}
            >
              {description}
            </div>
          </div>
          <div></div>
        </div>
      </Link>
    )
  }
}

class Index extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title="Projects"
          keywords={[
            `iOS`,
            `Apps`,
            `Eduardo`,
            `Arenas`,
            `developer`,
            `appstore`,
            `mobile`,
            `projects`,
          ]}
        />
        <h1>Personal Projects</h1>
        <Project
          name={`Easy HIIT Timer`}
          description={`Easy to use High Intensity Interval Training timer.`}
          icon={data.hiitIcon.childImageSharp.fixed}
          url={`/hiit`}
        />
        <Project
          name={`Live to GIF Share Tool`}
          description={`Quickly and easily share your Live Photos as animated GIFs in
          messages, social networks, and more.`}
          icon={data.ltgIcon.childImageSharp.fixed}
          url={`/livetogif`}
        />
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    hiitIcon: file(absolutePath: { regex: "/hiit-icon.png/" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ltgIcon: file(absolutePath: { regex: "/ltg-icon.png/" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`
